import styled from "@emotion/styled";

export const CardWrapper = styled.div<{
  isHovering?: boolean;
  width?: string;
}>`
  position: relative;
  height: 404px;
  margin: 16px;
`;
