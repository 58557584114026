import { theme } from "@/GlobalStyles";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Heading } from "styles/typography";
import ArrowButton from "../arrow-button";
import GoldenBack from "../golden-back";
import ServiceCard from "./components/service-card";
import {
  GapWrapper,
  HeadingWrapper,
  ServiceCardsWrapper,
  ServicesWrapper,
} from "./services";

interface Props {
  title?: string;
  button?: boolean;
  serviceData?: Array;
  RightPartWidth?: string;
}

const Services = ({ title, button, serviceData, RightPartWidth }: Props) => {
  return (
    <ServicesWrapper>
      <HeadingWrapper>
        <Heading>{title}</Heading>
        <br />
        <GapWrapper>
          <GoldenBack zIndex="-1" left={RightPartWidth || "240px"} />
        </GapWrapper>
      </HeadingWrapper>
      {button && (
        <Link to="">
          <ArrowButton
            title="SEE ALL SERVICES"
            titleColor={theme.colors.darkGolden}
            gradient
            border={`2px solid ${theme.colors.darkGolden}`}
            direction="row-reverse"
            rotate="rotate(45deg)"
            arrowMargin="30px 16px"
            mobileDirection="row-reverse"
          />
        </Link>
      )}
      <ServiceCardsWrapper className="no-scrollbar">
        {serviceData.map((item: any, ind: any) => (
          <ServiceCard
            key={ind}
            title={item.title}
            image={item.image}
            link={item?.link}
            alt={item.alt}
          />
        ))}
      </ServiceCardsWrapper>
    </ServicesWrapper>
  );
};

export default Services;
