export const HomeServices = [
  {
    title: "Orthodontics",
    image: "orthodontics.png",
    link: "/orthodontics-care/interceptive-orthodontic/",
    alt: "MJ Orthodontist - girl smiling",
  },
  {
    title: "CLEAR ALIGNERS",
    image: "alingers.png",
    link: "/orthodontics-care/clear-aligners/",
    alt: "MJ Orthodontist - clear aligners",
  },
  {
    title: "PEDIATRIC DENTISTRY",
    image: "pediatric-dentistry.png",
    link: "/pediatric-dentistry/pediatric/",
    alt: "MJ pediatric dentist - child in a dental office",
  },
  {
    title: "SEDATION",
    image: "sedations.png",
    link: "/pediatric-dentistry/sedation/",
    alt: "MJ pediatric dentist - child in a dental office",
  },
];
