import React from "react";
import { Title } from "styles/typography";
import {
  Address,
  AddressWrapper,
  HeroButtonsWrapper,
  HeroContainer,
  HeroContentWrapper,
  TitleWrapper,
} from "./hero-banner";
import ArrowButton from "@/common/arrow-button";
import Location from "svgs/location";
import { theme } from "@/GlobalStyles";
import { Link } from "gatsby";
import thumbnail from "../../../../../assets/hero-thumbnail.jpg";
import video from "../../../../../assets/hero.mp4";

const HeroBanner = () => {
  return (
    <HeroContainer>
      <video
        muted
        playsInline
        loop
        poster={thumbnail}
        controls={false}
        autoPlay
        title="MJ pediatric dentists sam and MJ Orthodontist blerta dental in their office"
      >
        <source src={video} type="video/mp4" />
      </video>
      <HeroContentWrapper>
        <TitleWrapper>
          <Title color={theme.colors.white}>
            Nurturing Smiles, Building Confidence
          </Title>
        </TitleWrapper>
        <AddressWrapper>
          <Location width="45px" />
          <a
            target="_blank"
            href="https://maps.app.goo.gl/3mB7SiLyGk7qK5XX8?g_st=ic"
            rel="noreferrer"
          >
            <Address>820 N Mt Juliet Rd, Mt Juliet TN 37122</Address>
          </a>
        </AddressWrapper>
        <HeroButtonsWrapper>
          <Link to="/contact">
            <ArrowButton
              title="Book An Appointment"
              titleColor={theme.colors.white}
              border="2px solid white"
              arrowMargin="10px 8px 10px 8px"
              mobileDirection="row-reverse"
            />
          </Link>
          <a href="tel:615 301-8660">
            <ArrowButton
              title="(615) 301-8660"
              titleColor={theme.colors.white}
              border="2px solid white"
              direction="row-reverse"
              rotate="rotate(45deg)"
              arrowMargin="10px 8px 10px 8px"
              mobileDirection="row-reverse"
            />
          </a>
        </HeroButtonsWrapper>
      </HeroContentWrapper>
    </HeroContainer>
  );
};

export default HeroBanner;
