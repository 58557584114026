import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const ReviewsMain = styled.div`
  height: 1017px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0px;
  margin-top: 60px;
  z-index: 1;
  position: relative;

  ${mq["lg"]} {
    height: 800px;
  }
  ${mq["sm"]} {
    height: 500px;
  }
`;

export const HeadingWrapper = styled.div`
  position: relative;
  max-width: 560px;
  width: 100%;
  z-index: 1;
  margin: 0px 20px;
  text-align: center;
`;

export const SmilesImageWrapper = styled.div`
  max-width: 863px;
  position: relative;
  width: 100%;
  height: 690px;
  margin: 60px 0px;
  left: -65px;
  ${mq["lg"]} {
    left: 0px;
    width: 80%;
  }
`;
export const DesktopHide = styled.div`
  display: none;
  ${mq["lg"]} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const SmileCenter = styled.div<{
  reverseAnimation?: any;
  forwardAnimation?: any;
  left?: string;
  bottom?: string;
  zIndex?: string;
  hover?: boolean;
}>`
  z-index: ${(props) => props.zIndex || ""};
  animation-duration: 1s;
  animation-fill-mode: forwards;
  position: absolute;
  max-height: 0px;
  left: ${(props) => props.left || ""};
  bottom: ${(props) => props.bottom || ""};
  animation-name: ${(props) =>
    props.hover ? props.forwardAnimation : props.reverseAnimation};
  ${mq["lg"]} {
    display: none;
  }
`;
export const SmileTopLeft = styled.div`
  position: absolute;
`;
