export const HappySmile = [
  {
    reverseAnimation1: "25%",
    reverseAnimation2: "15%",
    forwardAnimation1: "15%",
    forwardAnimation2: "25%",
    top: "25%",
    left: "30%",
    zIndex: "2",
    imgsrc: "smile_center.png",
    alt: "MJ pediatric dentist - a toddler smiling",
    width: "336.82px",
    tablet_width: "170px",
    mobile_width: "85px",
  },
  {
    reverseAnimation1: "50%",
    reverseAnimation2: "25%",
    forwardAnimation1: "25%",
    forwardAnimation2: "50%",
    top: "50%",
    left: "64%",
    zIndex: "1",

    alt: "MJ Orthodontist doctor blerta talking to two kids",
    imgsrc: "groups_kids.png",
    width: "401.19px",
    tablet_width: "150px",
    mobile_width: "75px",
  },
  {
    reverseAnimation1: "67%",
    reverseAnimation2: "25%",
    forwardAnimation1: "25%",
    forwardAnimation2: "67%",
    left: "10%",
    zIndex: "1",
    imgsrc: "girlfield_smilings.png",
    alt: "MJ pediatric dentist doctor sam talking to a child",
    width: "299.39px",
    tablet_width: "140px",
    mobile_width: "70px",
  },
  {
    reverseAnimation1: "25%",
    reverseAnimation2: "15%",
    forwardAnimation1: "25%",
    forwardAnimation2: "15%",
    top: "24%",
    left: "8%",
    zIndex: "2",
    imgsrc: "kid_smiling.png",

    alt: "MJ pediatric dentist - a child smiling",
    width: "306.88px",
    tablet_width: "150px",
    mobile_width: "75px",
  },
  {
    reverseAnimation1: "20%",
    reverseAnimation2: "9%",
    forwardAnimation1: "20%",
    forwardAnimation2: "9%",
    top: "20%",
    left: "60%",
    zIndex: "2",
    imgsrc: "kid_smiling2.png",
    alt: "MJ pediatric dentist doctor sam holding a child in her arms",
    width: "306.88px",

    tablet_width: "200px",
    mobile_width: "100px",
  },
];
