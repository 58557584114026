import React from "react";
import { HomePageContainer } from "./home";
import HeroBanner from "./components/hero-banner";
import IntroSection from "./components/intro-section";
import DoctorIntro from "@/common/doctor-intro";
import Services from "@/common/services";
import { DoctorData } from "constants/doctor-intro";
import { HomeServices } from "constants/home-services";
import GetInTouch from "@/common/get-in-touch";
import ContactForm from "@/common/contact-form";
import Reviews from "./components/reviews";
// import Gallery from "@/common/gallery";
// import MeetOurTeam from "./components/our-team";

const HomePage = () => {
  return (
    <HomePageContainer>
      <HeroBanner />
      <IntroSection />
      {DoctorData.map((item, ind) => (
        <DoctorIntro
          key={ind}
          image={item.image}
          aapdlogo={item.dlogo}
          drName={item.drName}
          drPost={item.drPost}
          certifications={item.certifications}
          quote={item.quote}
          description={item.description}
          buttonTitle={item.buttonTitle}
          crown={item.crown}
          flexDirection={item.flexDirection}
          link={item.link}
          animation={item.animation}
        />
      ))}
      {/* <MeetOurTeam /> */}
      <Services
        RightPartWidth="90%"
        title="OUR SERVICES"
        serviceData={HomeServices}
      />
      <Reviews />
      <GetInTouch />
      <ContactForm />
      {/* <Gallery /> */}
    </HomePageContainer>
  );
};

export default HomePage;
