import Image from "@/common/image";
import React from "react";
import { SubHeading } from "styles/typography";
import { CardWrapper } from "./service-card";
import ArrowButton from "@/common/arrow-button";
import { Link } from "gatsby";

interface Props {
  title?: string;
  image?: string;
  link?: string;
  alt?: string;
  isHovering?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}

const ServiceCard = ({
  title,
  image,
  link,
  isHovering,
  alt,
  onMouseOver,
  onMouseOut,
}: Props) => {
  return (
    <CardWrapper>
      <Image
        filename={image}
        alt={alt}
        width={
          isHovering && typeof window !== "undefined"
            ? `${window.innerWidth}px`
            : "300px"
        }
      />
      <SubHeading position="absolute" bottom={"70px"} left="10px" color="white">
        {title}
      </SubHeading>

      <Link
        to={link || ""}
        style={{ position: "absolute", bottom: "-15px", left: "12px" }}
      >
        <ArrowButton
          title={`Read about ${title?.toLowerCase()}`}
          titleColor={"white"}
          border={`2px solid white`}
          direction="row-reverse"
          rotate="rotate(45deg)"
          arrowMargin="30px 0px"
          mobileDirection="row-reverse"
        />
      </Link>
    </CardWrapper>
  );
};

export default ServiceCard;
