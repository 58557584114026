import React from "react";
import ArrowButton from "@/common/arrow-button";
import GoldenBack from "@/common/golden-back";
import Image from "@/common/image";
import { theme } from "@/GlobalStyles";
import { Heading, Paragraph } from "styles/typography";
import {
  HeadingWrapper,
  ImageWrapper,
  IntroContent,
  IntroContentWrapper,
  IntroMainContainer,
  IntroParagraphWrapper,
  MobilePadding,
} from "./intro-section";

const IntroSection = () => {
  return (
    <IntroMainContainer>
      <ImageWrapper data-aos="fade-right">
        <Image
          filename="intro-image.png"
          alt="MJ pediatric dentists sam and MJ Orthodontist blerta with kids"
        />
      </ImageWrapper>
      <IntroContent data-aos="fade-up">
        <MobilePadding>
          <HeadingWrapper>
            <Heading z-index="2">WELCOME TO PEARL</Heading>
            <GoldenBack left="85.5%" zIndex="-1" />
          </HeadingWrapper>
          <IntroParagraphWrapper>
            <Paragraph>
              As a dual specialty office we strive to provide the best pediatric
              dental and orthodontic care and make visits convenient and FUN! We
              have created a modern environment that both kids and parents can
              enjoy. Dr. Sam and Dr. Blerta are committed to providing
              personalized and compassionate care to create healthy and happy
              smiles!
            </Paragraph>
          </IntroParagraphWrapper>
          <a href="#contact">
            <ArrowButton
              title="Book An Appointment"
              titleColor={theme.colors.tomatoRed}
              border={`2px solid ${theme.colors.tomatoRed}`}
              direction="row-reverse"
              rotate="rotate(45deg)"
              mobileDirection="row-reverse"
            />
          </a>
        </MobilePadding>
      </IntroContent>
    </IntroMainContainer>
  );
};

export default IntroSection;
