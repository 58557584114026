import ArrowButton from "@/common/arrow-button";
import GoldenBack from "@/common/golden-back";
import Image from "@/common/image";
import { theme } from "@/GlobalStyles";
import { keyframes, css } from "@emotion/react";
import { HappySmile } from "constants/happy-smile";
import { StaticImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Heading } from "styles/typography";
import {
  HeadingWrapper,
  ReviewsMain,
  SmileCenter,
  SmilesImageWrapper,
  DesktopHide,
} from "./reviews";

const Reviews = () => {
  const reverseAnimation = (
    initial: string,
    hoverPoistion: string
  ) => keyframes`
    0% { top: ${initial} }
    100% { top: ${hoverPoistion} 
  }
`;

  const forwardAnimation = (
    initial: string,
    hoverPoistion: string
  ) => keyframes`
    0% { top: ${initial} }
    100% { top: ${hoverPoistion} 
  }
`;
  return (
    <ReviewsMain>
      <StaticImage
        src="../../../../images/review-bg.jpg"
        alt=""
        layout="fullWidth"
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
        `}
      />
      <HeadingWrapper data-aos="fade-right">
        <Heading z-index="1">HAPPY SMILES!</Heading>
        <GoldenBack left="77.5%" zIndex="-1" />
      </HeadingWrapper>
      <a
        href="https://www.google.com/search?q=pearl+pediatric+dentistry#lrd=0x88de0f515ac1c447:0x7d685c08fddddc28,1,,,"
        rel="noopener noreferrer"
        target="_blank"
      >
        <ArrowButton
          title="READ REVIEWS"
          gradient
          titleColor={theme.colors.darkGolden}
          border={`2px solid ${theme.colors.darkGolden}`}
          direction="row-reverse"
          rotate="rotate(45deg)"
          arrowMargin="40px 0px"
          mobileDirection="row-reverse"
        />
      </a>
      <SmilesImageWrapper>
        <DesktopHide>
          <Image filename="collage.png" />
        </DesktopHide>
        {HappySmile.map((items, index) => (
          <SmileCenter
            hover={true}
            key={index}
            reverseAnimation={reverseAnimation(
              `${items.reverseAnimation1}`,
              `${items.reverseAnimation2}`
            )}
            forwardAnimation={forwardAnimation(
              `${items.forwardAnimation1}`,
              `${items.forwardAnimation2}`
            )}
            bottom={items.bottom}
            left={items.left}
            zIndex={items.zIndex}
          >
            <Image
              filename={items.imgsrc}
              alt={items.alt}
              width={items.width}
              mobile_width={items.mobile_width}
              tablet_width={items.tablet_width}
            />
          </SmileCenter>
        ))}
      </SmilesImageWrapper>
    </ReviewsMain>
  );
};

export default Reviews;
