import { theme } from "@/GlobalStyles";
import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const HeroContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  position: relative;
  min-height: 570px;
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: -1;
  }

  > video:first-of-type {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${mq["header"]} {
    position: relative;
    top: -126px;
    margin-bottom: -126px;
    height: calc(100vh + 14px);
  }

  @media (min-width: 1280px) {
    height: calc(100vh - 116.8px);
  }
`;

export const TitleWrapper = styled.div`
  max-width: 600px;
`;

export const HeroContentWrapper = styled.div`
  padding: 56px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  max-width: 1440px;

  ${mq["lg"]} {
    padding: 16px;
    justify-content: flex-end;
  }
`;

export const AddressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const Address = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: ${theme.colors.white};
  padding-left: 15px;
`;

export const HeroButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 500px;
  width: 100%;

  ${mq["sm"]} {
    justify-content: flex-start;
  }

  p {
    font-size: 1rem;
  }

  @media (min-width: 1280px) {
    max-width: 550px;

    p {
      font-size: 1.125rem;
    }
  }
`;
