import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const ServicesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 60px 60px 0px 60px;
  width: 100%;

  ${mq["md"]} {
    margin: 60px 10px 0px 10px;
  }
`;

export const HeadingWrapper = styled.div`
  max-width: 560px;
  margin-bottom: 64px;
  position: relative;
  z-index: 1;
`;

export const ServiceCardsWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  max-width: 1350px;
  width: 100%;
`;
export const GapWrapper = styled.div`
  left: 40px;
`;
